var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
import React, { useState } from 'react';
import { Form } from 'antd';
import Input from '@fp-suite/core-ui/src/components/Input';
import Button from '@fp-suite/core-ui/src/components/Button';
import BackBtn from './BackBtn';
import { BsKeyboard, BsKeyboardFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
var DropLocation = function (_a) {
    var currentPickRunTypeId = _a.currentPickRunTypeId, inputTitle = _a.inputTitle, topTitle = _a.topTitle, onDropLocationInputChange = _a.onDropLocationInputChange, confirmDropoff = _a.confirmDropoff, errorMsgText = _a.errorMsgText, scannedLocationIncorrect = _a.scannedLocationIncorrect, setScannedLocationIncorrect = _a.setScannedLocationIncorrect, backBtn = _a.backBtn;
    var form = Form.useForm()[0];
    var _b = useState('none'), inputMode = _b[0], setInputMode = _b[1];
    var t = useTranslation().t;
    var cartScanData = localStorage.getItem('cartId');
    var currentIndex = 0;
    var hospitalContainers = localStorage.getItem('hospitalContainers');
    if (hospitalContainers) {
        hospitalContainers = JSON.parse(hospitalContainers);
    }
    else {
        hospitalContainers = [];
    }
    var packingStationContainers = localStorage.getItem('packingStationContainers');
    if (packingStationContainers) {
        packingStationContainers = JSON.parse(packingStationContainers);
    }
    else {
        packingStationContainers = [];
    }
    var parsedContainers = localStorage.getItem('pickRunContainerList');
    if (parsedContainers) {
        parsedContainers = JSON.parse(parsedContainers);
    }
    else {
        parsedContainers = [];
    }
    var onChangeInput = function (event) {
        onDropLocationInputChange(event.target.value);
        setScannedLocationIncorrect(false);
    };
    var clearInputOnConfirm = function () {
        form.setFieldsValue({
            scannedDropLocation: ''
        });
        confirmDropoff();
    };
    return (_jsxs("div", __assign({ className: 'py-7 px-10 w-full rounded-xl shadow-sm bg-white' }, { children: [_jsxs("div", __assign({ className: 'flex flex-row items-center border-b-2 border-gray-300 mb-6 pb-3' }, { children: [_jsx("h1", __assign({ className: 'text-base text-gray-600' }, { children: t('Pick Run Id') }), void 0), _jsx("h1", __assign({ className: 'mx-auto w-max font-medium text-base' }, { children: localStorage.getItem('pickRunId') }), void 0)] }), void 0), _jsxs("div", __assign({ className: 'flex flex-row items-center mb-3' }, { children: [_jsx("h1", __assign({ className: 'text-base text-gray-600' }, { children: t('Cart') }), void 0), _jsx("h1", __assign({ className: 'mx-auto w-max font-medium text-base' }, { children: cartScanData }), void 0)] }), void 0), _jsx("div", __assign({ className: 'my-3.5' }, { children: _jsx("h1", __assign({ className: 'mb-2 font-bold text-md' }, { children: topTitle }), void 0) }), void 0), _jsxs("div", __assign({ className: 'mb-3.5' }, { children: [_jsx("h1", __assign({ className: 'mb-2 font-bold text-md' }, { children: t('To Drop') }), void 0), inputTitle === t('Scan Hospital Location') &&
                        hospitalContainers &&
                        hospitalContainers.length > 0 &&
                        hospitalContainers.map(function (item) {
                            if (item.containerId) {
                                currentIndex += 1;
                                return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5 text-base' }, { children: [t('Tote'), " ", currentIndex] }), void 0), _jsx("h1", __assign({ className: 'text-base font-medium' }, { children: item.containerId }), void 0)] }), void 0));
                            }
                        }), inputTitle === t('Scan Hospital Location') &&
                        parsedContainers &&
                        parsedContainers.length > 0 &&
                        parsedContainers.map(function (item) {
                            var _a, _b, _c, _d;
                            var isFound = false;
                            for (var i = 0; i < hospitalContainers.length; i++) {
                                if (((_a = item === null || item === void 0 ? void 0 : item.description) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                    ((_b = hospitalContainers[i]['containerId']) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
                                    isFound = true;
                                }
                            }
                            for (var i = 0; i < packingStationContainers.length; i++) {
                                if (((_c = item.description) === null || _c === void 0 ? void 0 : _c.toLowerCase()) ===
                                    ((_d = packingStationContainers[i]['containerId']) === null || _d === void 0 ? void 0 : _d.toLowerCase())) {
                                    isFound = true;
                                }
                            }
                            if (!isFound) {
                                currentIndex += 1;
                                return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5 text-base' }, { children: [t('Tote'), " ", currentIndex] }), void 0), _jsx("h1", __assign({ className: 'text-base font-medium' }, { children: item.description }), void 0)] }), void 0));
                            }
                        }), inputTitle === t('Scan Packing Station') &&
                        (currentPickRunTypeId === 'PrtBulkSingle' ||
                            currentPickRunTypeId === 'PrtSingle' ||
                            currentPickRunTypeId === 'PrtOrderLevel') &&
                        packingStationContainers &&
                        packingStationContainers.length > 0 &&
                        // eslint-disable-next-line
                        packingStationContainers.map(function (item) {
                            var isFound = false;
                            hospitalContainers.forEach(function (innerItem) {
                                if (innerItem.containerId === item.containerId) {
                                    isFound = true;
                                }
                            });
                            if (!isFound && item.containerId) {
                                currentIndex += 1;
                                return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5 text-base' }, { children: [t('Tote'), " ", currentIndex] }), void 0), _jsx("h1", __assign({ className: 'text-base font-medium' }, { children: item.containerId }), void 0)] }), void 0));
                            }
                        }), inputTitle === t('Scan Packing Station') &&
                        currentPickRunTypeId !== 'PrtSingle' &&
                        currentPickRunTypeId !== 'PrtOrderLevel' &&
                        parsedContainers &&
                        parsedContainers.length > 0 &&
                        // eslint-disable-next-line
                        parsedContainers.map(function (item) {
                            currentIndex += 1;
                            return (_jsxs("div", __assign({ className: 'flex flex-row items-center' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-gray-400 mr-5 text-base' }, { children: [t('Tote'), " ", currentIndex] }), void 0), _jsx("h1", __assign({ className: 'text-base font-medium' }, { children: item.description ? item.description : item.serialNumber ? item.serialNumber : '' }), void 0)] }), void 0));
                        })] }), void 0), _jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedLocationIncorrect ? 'error' : '', help: scannedLocationIncorrect && errorMsgText, className: 'w-full', name: 'scannedDropLocation' }, { children: _jsx(Input, { placeholder: '', title: inputTitle, onChange: onChangeInput, autoFocus: true, allowClear: true, fromHandheld: true, inputMode: inputMode }, void 0) }), void 0) }), void 0), !inputMode ? (_jsx(BsKeyboardFill, { size: 32, className: 'mt-1 ml-2 cursor-pointer', color: '#093479', onClick: function () { return setInputMode('none'); } }, void 0)) : (_jsx(BsKeyboard, { size: 32, className: 'mt-1 ml-2 cursor-pointer', onClick: function () { return setInputMode(''); } }, void 0))] }), void 0), _jsx("div", __assign({ className: 'mt-5' }, { children: _jsxs("div", __assign({ className: 'flex flex-row items-center justify-between' }, { children: [_jsx(BackBtn, { onClick: backBtn }, void 0), _jsx(Button, { buttonText: t('Confirm Dropoff'), onClick: clearInputOnConfirm }, void 0)] }), void 0) }), void 0)] }), void 0));
};
export default DropLocation;
