var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay-ts';
import queryString from 'query-string';
import Sidebar from '../components/Sidebar';
import PickRuns from '../components/PickRuns';
var PickRunsScreen = function (props) {
    var parsed = queryString.parse(props.location.search);
    var queryStr = '';
    if (parsed.queryString) {
        queryStr = parsed.queryString;
    }
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0) }, { children: _jsx(Sidebar, __assign({ setIsLoading: setIsLoading }, { children: _jsx(PickRuns, { setIsLoading: setIsLoading, queryString: queryStr }, void 0) }), void 0) }), void 0));
};
export default PickRunsScreen;
